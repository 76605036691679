import React from 'react';
import styled from 'styled-components';

import ImgText from '../sections/ImgText';
import Text from '../sections/Text';
import TextImgText from '../sections/TextImgText';
import Imgs from '../sections/Imgs';
import Numbers from '../sections/Numbers';

const Wrapper = styled.div`
  padding-top: 58px;

  @media only screen and (max-width: 810px) {
    padding-top: 18px;
  }
`;

const Sections = ({ data, locs }) => {
  return (
    <Wrapper>
      {data.map((section, i) => {
        if (section.imgText) {
          return (
            <ImgText
              key={i}
              data={section}
              locs={locs[0] && locs[0].sections[i]}
            />
          );
        } else if (section.textImgText) {
          return (
            <TextImgText
              key={i}
              data={section}
              locs={locs[0] && locs[0].sections[i]}
            />
          );
        } else if (section.imgs) {
          return (
            <Imgs
              key={i}
              data={section}
              locs={locs[0] && locs[0].sections[i]}
            />
          );
        } else if (section.numbers) {
          return (
            <Numbers
              key={i}
              data={section}
              locs={locs[0] && locs[0].sections[i]}
            />
          );
        } else {
          return (
            <Text
              key={i}
              data={section}
              locs={locs[0] && locs[0].sections[i]}
            />
          );
        }
      })}
    </Wrapper>
  );
};

export default Sections;
