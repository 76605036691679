import React, { useEffect } from 'react';
import ContextConsumer from '../helpers/context';
import SEO from '../components/Seo';

import Top from '../components/article/Top'
import Sections from '../components/article/Sections'
import Bottom from '../components/article/Bottom'

import Footer from '../components/layout/Footer';

const Article = ({ pageContext, contextSet, contextData }) => {
  const { data, otherLangLink, otherLocale } = pageContext;

  const { seoTitle, seoDescription, seoImage, sections, localizations } = data;

  useEffect(() => {
    contextSet({ langTo: otherLangLink });
  }, []);

  return (
    <div>
      <SEO title={seoTitle} description={seoDescription} image={seoImage} />
      <Top data={data} />
      <Sections data={sections} locs={localizations} />
      
      <Bottom>
      <Footer isEn={otherLocale !='en'} />
      </Bottom>
    </div>
  );
};

const ConnectedArticle = ({ pageContext }) => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Article
          pageContext={pageContext}
          contextSet={set}
          contextData={data}
        />
      )}
    </ContextConsumer>
  );
};

export default ConnectedArticle;
