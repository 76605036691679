import React from 'react';
import styled from 'styled-components';

import RichText from '../shared/RichText';

import W from '../styled/Wrapper';
import H from '../styled/Heading';
import P from '../styled/Paragraph';
import B from '../styled/Button';

const TextWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};
`;

const Heading = styled(H)`
  @media only screen and (max-width: 810px) {
    font-size: 36px;
    line-height: 43px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
`;

const Paragraph = styled(P)`
  max-width: 907px;
  padding-bottom: 25px;

  @media only screen and (max-width: 810px) {
  padding-bottom: 15px;
      
  }
`;

const Top = ({ data }) => {
  const { title, intro } = data;

  return (
    <W>
      <TextWrapper>
        <Heading size={1} as={'h1'}>
          {title}
        </Heading>
        <Paragraph>{data.intro}</Paragraph>
      </TextWrapper>
    </W>
  );
};

export default Top;
